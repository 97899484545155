import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
	name: 'auth/session',
	initialState: {
        token: '',
        tokenContent: {
            rvToken: '',
            spplToken: ''
        },
        signedIn: false,
    },
	reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true;
            // state.token = action.payload;
            state.tokenContent = {
                ...state?.tokenContent,
                rvToken: action.payload
            };
        },
        onSignInSpplSuccess: (state, action) => {
            state.signedIn = true;
            state.tokenContent = {
                ...state?.tokenContent,
                spplToken: action.payload
            };
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false;
            if (state.token) state.token = '';
            state.tokenContent = {
                ...state?.tokenContent,
                spplToken: '',
                rvToken: ''
            };
        },
        setToken: (state, action) =>  {
            state.token = action.payload;
            state.tokenContent = {
                ...state?.tokenContent,
                rvToken: action.payload
            };
        }
	},
})

export const { onSignInSuccess, onSignInSpplSuccess, onSignOutSuccess, setToken } = sessionSlice.actions

export default sessionSlice.reducer
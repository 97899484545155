import { ApiService, SpplApiService } from "./ApiService";

export async function spplApiSignIn(data) {
  return SpplApiService.fetchData({
    url: "/v1/auth/password",
    method: "post",
    data,
  });
}

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: "/v1/auth/password",
    method: "post",
    data,
  });
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: "/sign-up",
    method: "post",
    data,
  });
}

// export async function apiSignOut(data) {
//   return ApiService.fetchData({
//     url: "/sign-out",
//     method: "post",
//     data,
//   });
// }

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: "/forgot-password",
    method: "post",
    data,
  });
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: "/reset-password",
    method: "post",
    data,
  });
}

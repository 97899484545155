import ReactPaginate from 'react-paginate';

const Pagination = (props) => {
    const { previousLabel = 'Previous', nextLabel = 'Next', onPageChange, pageRangeDisplayed = 4, marginPagesDisplayed = 2, pageCount, ...rest } = props;
    return (
        <ReactPaginate
            previousLabel={previousLabel}
            nextLabel={nextLabel}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={marginPagesDisplayed}
            pageRangeDisplayed={pageRangeDisplayed}
            onPageChange={onPageChange}
            renderOnZeroPageCount={null}
            containerClassName={'flex items-center space-x-1 text-black'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link px-2 py-1  rounded hover:bg-gray-200'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link px-2 py-1 border border-blue-500  rounded hover:bg-gray-200'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link px-2 py-1 border border-blue-500 rounded hover:bg-gray-200'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link px-2 py-1 rounded'}
            activeClassName={'bg-blue-500 text-white'}
            {...rest}
        />
    );
};

export default Pagination;
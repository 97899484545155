import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import { apiSignIn, apiSignUp, spplApiSignIn } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess, onSignInSpplSuccess } from "store/auth/sessionSlice";

import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, tokenContent, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async (values) => {
    try {
      const { userId = "" } = values;
      const domain  = userId.split("@")[1];
      if (domain === 'sppl.com') {
        const resp = await spplApiSignIn(values);
        if (resp?.data) {
          const { authToken, user } = resp?.data;
          user.sideMenu = 'sppl';
          dispatch(onSignInSpplSuccess(authToken));
          dispatch(setUser(user));
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.spplAuthenticatedEntryPath);
      } else {
        const rvResp = await apiSignIn(values);
        if (rvResp?.data) {
          const { authToken, user } = rvResp?.data;
          dispatch(onSignInSuccess(authToken));
          dispatch(setUser(user));
        }
        const spplResp = await spplApiSignIn(values).catch((error) => {
          console.error("Error - sppl login", error);
          return null;
        });
        if (spplResp?.data) {
          const { authToken, user } = spplResp?.data;
          dispatch(onSignInSpplSuccess(authToken));
          dispatch(setUser(user));
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
      }
      return {
        status: "success",
        message: "",
      };
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: "",
                userName: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
  };

  return {
    authenticated: ((tokenContent?.rvToken || token) || tokenContent?.spplToken) && signedIn,
    spplOnly: !tokenContent?.rvToken && tokenContent?.spplToken,
    signIn,
    signUp,
    signOut,
  };
}

export default useAuth;

import BaseService from './BaseService'
import SpplBaseService from './SpplBaseService'

export const ApiService = {
    fetchData(param) {
        return new Promise((resolve, reject) => {
            BaseService(param).then(response => {
                resolve(response)
            }).catch(errors => {
                reject(errors)
            })
        })
    }
}

export const SpplApiService = {
    fetchData(param) {
        return new Promise((resolve, reject) => {
            SpplBaseService(param).then(response => {
                resolve(response)
            }).catch(errors => {
                reject(errors)
            })
        })
    }
};
const appConfig = {
  apiPrefix: "https://api.iracemedia.com/api",
  // apiPrefix: "http://localhost:3200/api",
  spplApiPrefix: 'https://sppl-api.com/api',
  // spplApiPrefix: 'http://localhost:3300/api',
  authenticatedEntryPath: "/home",
  spplAuthenticatedEntryPath: "/sppl-race-calendar",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "",
  enableMock: false,
};

export default appConfig;
